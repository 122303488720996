/* tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    @apply h-screen font-open text-lg text-gray-dark;
  }

  a {
    @apply hover:text-blue;
  }

  label {
    @apply text-gray-dark text-base mb-1;
  }

  input {
    @apply border border-gray-mid rounded-md h-10 text-gray-dark font-medium p-4;
  }

  textarea {
    @apply border border-gray-mid rounded-md h-10 text-gray-dark font-medium p-4;
  }

  input:focus {
    @apply border-2 border-blue;
  }

  input:read-only {
    @apply bg-gradient-to-t from-gray-200 to-gray-100 text-gray-mid border-gray-light;
  }

  h2 {
    @apply font-bold;
  }

}

@layer utilities {
  .btn-primary {
    @apply text-white px-3 py-2 rounded-lg hover:opacity-95 font-bold text-center leading-none;
    background: linear-gradient(#1571D0, #1898E3);
    background-repeat: no-repeat;
    &:hover {
      background: linear-gradient(#1571D0, #1571D0);
      color: white;
    }
  }

  .btn-secondary {
    @apply text-gray-dark hover:text-gray-dark px-3 py-2 rounded-lg border border-gray-light font-bold text-center leading-none;
    background: linear-gradient(#E9E8EA, #D9D8DA);
    background-repeat: no-repeat;
    &:hover {
      background: linear-gradient(#E9E8EA, #CCCBCD);
    }
  }

  .btn-alert {
    @apply text-white hover:text-white px-3 py-2 rounded-lg border border-gray-light font-bold text-center leading-none bg-red hover:bg-black;
  }

  .btn-tertiary {
    @apply text-gray-dark hover:text-gray-dark px-3 py-2 rounded-lg border border-yellow font-bold text-center leading-none;
    background: linear-gradient(#FFD623, #FFA903);
    background-repeat: no-repeat;
    &:hover {
      background: linear-gradient(#FFD623, #F79726);
    }
  }
  .subscription-button label {
    @apply text-gray-dark hover:text-gray-dark px-3 py-2 rounded-lg border border-gray-light font-bold text-center inline-block w-full;
    background: linear-gradient(#E9E8EA, #D9D8DA);
    background-repeat: no-repeat;
    &:hover {
      background: linear-gradient(#E9E8EA, #CCCBCD);
    }
  }

  .subscription-button input[type="radio"]:checked + label {
    @apply text-gray-dark hover:text-gray-dark px-3 py-2 rounded-lg border border-yellow font-bold text-center;
    background: linear-gradient(#FFD623, #FFA903);
    background-repeat: no-repeat;
    &:hover {
      background: linear-gradient(#FFD623, #F79726);
    }
  }
}

.bg-gradient {
  background: linear-gradient(#F2F0ED, #ffffff);
  background-repeat: no-repeat;
}

.header {
  background-image: url(/app/assets/images/Nav.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content;
  background-color: #FFD623;
  @media only screen and (max-width: 1023px) {
    background-image: url(/app/assets/images/Nav-mobile.svg);
  }
}

.ex1-input {
  border: 1px solid #666666;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.radio-button input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-button label {
  display: inline-block;
  background: linear-gradient(#F2F0ED, #ffffff);
  padding: 0.5rem 2rem;
  border: 1px solid #CCCBCD;
  border-radius: 0.3rem;
  margin-right: 0.1rem;
}

.radio-button input[type="radio"]:checked + label {
  background: linear-gradient(#1571D0, #1898E3);
  border-color: #CCCBCD;
  color: #ffffff;
}

.radio-button input[type="radio"]:focus + label {
  border: 1px  solid #1571D0;
}

.subscription-button input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.subscription-button input[type="radio"]:focus + label {
  border: 1px  solid #1571D0;
}

/* Hide Hamburger */
#hamnav label, #hamburger { display: none; }

/* Horizontal Menu Items */
#hamitems { display: flex; }
#hamitems a {
  text-align: center;
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 1023px){
  /* Show Hamburger Icon */
  #hamnav label {
    display: inline-block;
  }

  /* Break down menu items into vertical */
  #hamitems a {
    box-sizing: border-box;
    display: block;
    width: 100%;
  }

  /* Toggle Show/Hide Menu */
  #hamitems { display: none; }
  #hamnav input:checked ~ #hamitems { display: block; }
}

.errors {
  background: lighten(#E34918, 48%);
  border: 2px solid #E34918 !important;
  color: #E34918;
  padding-left: 0.5rem;
  width: 100%;
  &[type=checkbox]{
    width: fit-content;
  }
}
